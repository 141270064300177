<template>
    <div class="sidebar-wrap">
        <div class="backdrop"  
        @click="closeSidebar" 
        :class="{ 'backdrop--active' : isSidebarOpen }">
        </div>

        <div class="sidebar" :class="{ 'sidebar--active' : isSidebarOpen }">
          <img src="../../assets/img/icon_close.svg" alt="close" @click="closeSidebar" >
          <div class="sidebar-nav">
            <div v-if="isUserLoggedIn" class="sidebar-nav-item" @click="closeSidebar">
              <router-link :to="{ name: 'events' }">EVENTS</router-link>
            </div>
            <div class="sidebar-nav-item" @click="closeSidebar">
              <router-link :to="{ name: 'contact' }">CONTACT</router-link>
            </div>
            <div class="sidebar-nav-item">
              <a href="https://californiawines.co.uk/" target="_blank">CALIFORNIA WINES</a>
            </div>
          </div>

          <div class="sidebar-socials">
            <a href="https://www.facebook.com/CaliforniaWines" target="_blank">
              <img src="../../assets/img/icon_facebook.svg" alt="Facebook">
            </a>
            <a href="https://www.instagram.com/California.wines.uk/" target="_blank">
              <img src="../../assets/img/icon_insta.svg" alt="Instagram">
            </a>
            <a href="https://twitter.com/CalifWinesUK" target="_blank">
              <img src="../../assets/img/icon_twitter.svg" alt="Twitter">
            </a>
          </div>
        </div>
    </div>

</template>

<script>
import store from '@/store/index.js'

export default {
  name: 'Sidebar',
  methods: {
    closeSidebar() {
      store.commit('toggleNav')
    }
  },
  computed: {
    isSidebarOpen() {
        return store.state.isNavOpen
    },
    isUserLoggedIn() {
      return store.state.isLoggedIn
    }
  }
}
</script>