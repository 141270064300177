import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { InMemoryCache } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = ''

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const fragmentCache = new InMemoryCache({ fragmentMatcher });

// Config
const defaultOptions = {
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  cache: fragmentCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

const clientAOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: 'https://api.bottlebooks.me/graphql',
  AUTH_TOKEN: 'Bearer KqNrVka8O64EYkzOnNK3eQ8l07okdqKUQp25dMw0l0ePKNwNncoBgtdJfAu5dJPHh7jVsRm1LBWhSZ0fgtjtCPjdUVFgIPWdYVMzWFKKY3N1SKGlpEmZ2dHVfc2qKiEjyzCBZ2rWyWhLMNz5yZN1eGmZh6nkc3I7haz8OPWQIxjKDPu0WgbnNcxByol4GqOCib3Q287njs4BBd42hnSKvqU5KSa1WEHYEx9Kj7bfJQ5rNpbrOx5pr54pHYg4125n'
}

const clientBOptions = {
  httpEndpoint: 'https://californiawines.co.uk/api',
}


// Call this in the Vue app file
export function createProvider () {
  const createA = createApolloClient({
    ...defaultOptions,
    ...clientAOptions,
  });

  const createB = createApolloClient({
    ...defaultOptions,
    ...clientBOptions,
  });

  const bottleBooks = createA.apolloClient;
  const craft = createB.apolloClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      bottleBooks,
      craft
    },
    defaultClient: bottleBooks,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
        fetchOptions: {
          mode: 'no-cors',
        },
        fetchPolicy: 'no-cache',
      },
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
