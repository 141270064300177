import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNavOpen: false,
    isNavUserOpen: false,
    isLoginOpen: false,
    isLoggedIn: false,
    userID: String,
    wineListId: String,
    visitedEvent: String,
    visitedEventId: String,
    userDestination: String,
    userRole: "none" // moderator, participant, viewer
  },
  mutations: {
    toggleNav() {
      this.state.isNavOpen = !this.state.isNavOpen
    },
    toggleNavUser() {
      this.state.isNavUserOpen = !this.state.isNavUserOpen
    },
    toggleLogin() {
      this.state.isLoginOpen = !this.state.isLoginOpen
    },
    logInUser() {
      this.state.isLoggedIn = true
    },
    logOutUser() {
      localStorage.removeItem('userID')
      this.state.isLoggedIn = false
      this.state.userID = ""
    },
    setUserID(state, id) {
      localStorage.setItem('userID', id)
      state.userID = id
    },
    initialiseStore(state) {
      if (localStorage.getItem('userID')) {
        state.userID = localStorage.getItem('userID')
        this.state.isLoggedIn = true
      }

      if (localStorage.getItem('visitedEvent')) {
        state.visitedEvent = localStorage.getItem('visitedEvent');
      }

      if (localStorage.getItem('visitedEventId')) {
        state.visitedEventId = localStorage.getItem('visitedEventId');
      }

      if (localStorage.getItem('wineListId')) {
        state.wineListId = localStorage.getItem('wineListId');
      }
    },
    setwineListId(satate, id) {
      localStorage.setItem('wineListId', id);
      satate.wineListId = id;
    },
    setvisitedEvent(state, slug) {
      localStorage.setItem('visitedEvent', slug);
      state.visitedEvent = slug;
    },
    setvisitedEventId(state, id) {
      localStorage.setItem('visitedEventId', id);
      state.visitedEventId = id;
    },
    setuserDestination(state, slug) {
      state.userDestination = slug;
    },
    setUserRole(state, role) {
      localStorage.setItem('userRole', role);
      state.userRole = role;
    }
  },
  getters: {
    userLoggedIn: state => {
      return state.isLoggedIn;
    },
    getwineListId: state => {
      return state.wineListId;
    },
    getvisitedEvent: state => {
      return state.visitedEvent;
    },
    getvisitedEventId: state => {
      return state.visitedEventId;
    },
    getuserDestination: state => {
      return state.userDestination;
    },
    getUserRole: state => {
      return state.userRole;
    },
    getUserID: state => {
      return state.userID;
    }
  },
  actions: {
  },
  modules: {
  }
})


