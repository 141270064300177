<template>
  <div class="landing">
    <div class="landing-background">
      <img src="../assets/img/background_new.jpg" alt="">
    </div>

    <div class="landing-content">

    </div>
    <Cookies/>
  </div>
</template>
<script>
  import Cookies from '@/components/snippets/Cookies.vue';
  import store from '@/store/index.js'

  export default  {
    components: { 
        Cookies
    },

    methods: {
    openPopup() {
      store.commit('toggleLogin')
    },
  },
  }
</script>

