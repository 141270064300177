<template>
  <div class="header" :key="componentKey" :class="{ 'header--white' : isLanding == false && isHolding == false }">
    <div class="hamburger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggle">
        <span class="line" :class="{ 'line--white' : isLanding || isHolding  }"></span>
        <span class="line" :class="{ 'line--white' : isLanding || isHolding  }"></span>
        <span class="line" :class="{ 'line--white' : isLanding || isHolding  }"></span>
    </div>

    <div v-if="isUserLoggedIn" class="logo">
      <router-link :to="{ name: 'events' }">
        <picture v-if="isLanding || isHolding">
          <source
              media="(max-width:768px)"
              srcset="../../assets/img/logo_mobile.svg"
            />
          <img src="../../assets/img/logo.svg" alt="Logo" />
        </picture>

        <picture v-if="isEvent">
          <img style="margin-top: -10px;" src="../../assets/img/logo_new.svg" alt="Logo" />
        </picture>

        <picture v-else>
          <img style="margin-top: -10px;" src="../../assets/img/logo_new.svg" alt="Logo" />
        </picture>
      </router-link>
    </div>

    <div v-else class="logo">
      <router-link :to="{ name: 'landing' }">
        <picture v-if="isLanding || isHolding">
          <source
              media="(max-width:768px)"
              srcset="../../assets/img/logo_mobile.svg"
            />
          <img src="../../assets/img/logo.svg" alt="Logo" />
        </picture>

        <picture v-else>
          <img style="margin-top: -10px;" src="../../assets/img/logo_new.svg" alt="Logo" />
        </picture>
      </router-link>
    </div>

    <div class="backdrop"  
      @click="toggleUser" 
      :class="{ 'backdrop--active' : isNavDropdownOpen }">
    </div>

    <div v-if="isHolding == false" class="sub-menu">
      <div v-if="isUserLoggedIn && !this.isFetching" class="user-nav" :data-user-fullname="this.user.fullName">
        <img v-if="this.user.profilePicture.length > 0" :src="this.user.profilePicture[0].url"  class="img-user" alt="" :class="{ 'active' : isNavUserActive }" @click.prevent="toggleUser">
        <p v-else class="pfp-initials unselectable" :class="{ 'active' : isNavUserActive }" @click.prevent="toggleUser">{{ getInitials(this.user.fullName) }}</p>

        <div class="nav-dropdown" :class="{ 'nav-dropdown--active' : isNavDropdownOpen }">
          <img src="../../assets/img/icon_close.svg" class="close" alt="close" @click="toggleUser" >

          <img v-if="!this.isFetching && this.user.profilePicture.length > 0" :src="this.user.profilePicture[0].url"  class="img-user" alt="" :class="{ 'active' : isNavUserActive }" @click.prevent="toggleUser">
          <p v-else class="pfp-initials unselectable" :class="{ 'active' : isNavUserActive }" @click.prevent="toggleUser">{{ getInitials(this.user.fullName) }}</p>

          <router-link :to="{ name: 'account' }"><p @click="toggleUser">PROFILE</p></router-link>
          <p @click="logout">LOGOUT</p>
        </div>
      </div>

      <p v-else @click.prevent="openPopup">LOGIN</p> 
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js'
/*import $ from 'jquery'*/

import gql from 'graphql-tag'


export default {
  name: 'Header',

  data() {
      return {
          isFetching: true,
          userID: localStorage.getItem('userID'),
          user: [],
          componentKey: 0,
      }
  },

  computed: {
    isBurgerActive() {
        return store.state.isNavOpen
    },
    isNavUserActive() {
      return store.state.isNavUserOpen
    },
    isNavDropdownOpen() {
        return store.state.isNavUserOpen
    },
    isLanding() {
      return false
    },
    isHolding() {
      return this.$route.name == 'holding'
    },
    isEvent() {
      if (this.$route.name == 'events' || this.$route.name == 'test' ) {
        return true;
      } else {
        return false;
      }
    },
    isUserLoggedIn() {
      return store.state.isLoggedIn
    },
    
  },

  methods: {
    toggle() {
      store.commit('toggleNav')
    },
    toggleUser() {
      store.commit('toggleNavUser')
    },
    openPopup() {
      store.commit('toggleLogin')
    },
    logout() {
      store.commit('toggleNavUser')
      store.commit('logOutUser')
      this.$router.go(0);
    },
    getInitials(name) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials
    }
  },

  watch: {
    isUserLoggedIn() {
      console.log(`User change`)
      this.componentKey += 1;

      if (store.state.isLoggedIn) {
        this.isFetching = true;

        console.log("refetch");
        this.$apollo.query({
          client: 'craft',
          httpEndpoint: 'https://californiawines.co.uk/web/api',
          query: gql`query ($userID: [QueryArgument]) {
              user(id: $userID) {
                username
                fullName
                  ... on User {
                      profilePicture {
                          ... on user_Asset {
                          id
                          url
                          }
                      }
                  }
              }
          }`,
          fetchPolicy: 'network-only',
          variables: {
            userID: localStorage.getItem('userID')
          }
        }).then((data) => {
          console.log(data);
          this.user = data.data.user
          this.isFetching = false;
        }).catch((error) => {
          // Error
          console.error("ERROR: " + error);
        })
      }
    }
  },

  mounted() {
    console.log("mounted");

    if (store.state.isLoggedIn) {
      this.$apollo.query({
        client: 'craft',
        httpEndpoint: 'https://californiawines.co.uk/web/api',
        query: gql`query ($userID: [QueryArgument]) {
            user(id: $userID) {
              username
              fullName
                ... on User {
                    profilePicture {
                        ... on user_Asset {
                        id
                        url
                        }
                    }
                }
            }
        }`,
        fetchPolicy: 'network-only',
        variables: {
          userID: this.userID
        }
      }).then((data) => {
        this.user = data.data.user
        this.isFetching = false;
      }).catch((error) => {
        // Error
        console.error("ERROR: " + error);
      })
    }
  },
}
</script>